import { environment as original } from './environment.prod';

export const environment = {
  ...original,
  name: 'prod-dennys-style',
  backgroundAdPlayerColor: '#EE3338',
  featureToggle: {
    ...original.featureToggle,
    theme: 'dennys',
    tabBarAutoHide: true,
    idleDelay: 30
  }
};
